<template>

    <div id="tools-page" class="container-fluid">

        <div class="spaced-elements">
            <div>
                <h2>Searchable catalogue of patient derived models </h2>
                <p class="small">(last updated {{ dateUpdated }})</p>
            </div>

            <div class="layout-buttons">
                <p style="line-height: 2rem; margin-right: 5px;">Select a layout:</p>
                <button v-for="layout in layoutOptions" :key="layout.key"
                    :style="`background-color: ${currLayout === layout.key ? 'lightgray' : 'white'}`"
                    :title="layout.desc" @click="changeLayout(layout.key)">
                    <img :src="require(`../assets/images/icons/${layout.key}.svg`)" />
                </button>
            </div>
        </div>

        <div id="tools-table" class="row">
            <div class="filter-tools col-xs-2 col-sm-2 col-md-3 col-lg-3">
                <div class="filter-group" style="flex-grow: 1">
                    <h3 class="dropdown-menu-label">Grouping</h3>
                    <b-form-select id="colorby-dropdown" @change="colorBy = getColumnCounts(selectedSegment, data)"
                        v-model="selectedSegment" :options="segmentOptions" />
                </div>

                <div class="filter-group" style="flex-grow: 2; height: 63vh;">
                    <h3 class="dropdown-menu-label">Filters</h3>

                    <div>
                        <h4>Cell Line Model</h4>
                        <div class="search-container">
                            <b-form-input id="cell-model-search" v-model="filter.searchText"
                                placeholder="Search by cell model ID..." @input="updateSearchFilters" />
                            <span id="clear-search" class="clear-button"
                                @click="updateSearchFilters('')">&#x2715;</span>
                        </div>


                    </div>

                    <h4 style="margin-top: 15px;">Lineage > Diagnosis > Diagnosis Subtype</h4>

                    <b-button block @click="resetFilters()" variant="outline-secondary"
                        class="clear-checked-filters">Clear All
                        Lineage Filters <span class="clear-button">&#x2715;</span></b-button>

                    <div id="filter-panel-wrapper">
                        <ul>
                            <li v-for="(lineage, i) in treeData.children" :key="i">
                                <div class="dropdown-menu-button">
                                    <span class="dropdown-menu-carrot" v-if="lineage.children"
                                        @click="toggleCarrot(`collapse-${toKebab(lineage.name)}`)"
                                        :id="'collapse-' + toKebab(lineage.name) + '-carrot'"
                                        v-b-toggle="[`collapse-${toKebab(lineage.name)}`]">
                                        +
                                    </span>
                                    <b-form-checkbox :checked="isSelected(lineage)" @change="onCheckFilter(lineage)"
                                        :key="i">
                                        {{ lineage.name }} ({{ lineage.short }})
                                    </b-form-checkbox>

                                    <p v-if="lineage.total > 0">({{ lineage.total }})</p>
                                </div>
                                <b-collapse :id="`collapse-${toKebab(lineage.name)}`" class="dropdown-menu-options">
                                    <ul>
                                        <li v-for="(diagnosis, j) in lineage.children" :key="j">
                                            <div class="dropdown-menu-button"
                                                :style="`margin-left: ${diagnosis.children.length > 0 ? '16px' : '30px'};`">
                                                <span class="dropdown-menu-carrot" v-if="diagnosis.children.length > 0"
                                                    :id="'collapse-' + toKebab(diagnosis.name) + '-carrot'"
                                                    v-b-toggle="[`collapse-${toKebab(diagnosis.name)}`]"
                                                    @click="toggleCarrot(`collapse-${toKebab(diagnosis.name)}`)">
                                                    +
                                                </span>
                                                <b-form-checkbox :checked="isSelected(diagnosis)" :key="j"
                                                    @change="onCheckFilter(diagnosis)">
                                                    {{ diagnosis.name }} ({{ diagnosis.short }})
                                                </b-form-checkbox>

                                                <p v-if="diagnosis.value > 0">({{ diagnosis.value }})</p>

                                            </div>
                                            <b-collapse :id="`collapse-${toKebab(diagnosis.name)}`"
                                                class="dropdown-menu-options">
                                                <ul style="margin-left: 50px;">
                                                    <li v-for="(subtype, k) in diagnosis.children" :key="k">
                                                        <b-form-checkbox
                                                            :checked="checkDiagnosisSubtype(subtype, diagnosis)"
                                                            @change="onCheckFilter(subtype)">
                                                            <span :title="subtype.name">{{ cutText(subtype.name) }}
                                                                <span v-if="subtype.short">({{ subtype.short
                                                                    }})</span></span>
                                                            <span style="margin-left: auto; color: silver;"
                                                                v-if="subtype.value > 0">({{ subtype.value }})</span>
                                                        </b-form-checkbox>


                                                    </li>
                                                </ul>
                                            </b-collapse>
                                        </li>
                                    </ul>
                                </b-collapse>

                            </li>
                        </ul>
                    </div>
                </div>

            </div>



            <div id="data-panel" class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                <div class="viz-container" :style="`height: ${showViz ? '38vh' : 'fit-content'}`">
                    <div class="filter-indicator" v-if="hasFilters">
                        <p>Filtering by: <span v-if="hasChecked" class="filter-type">{{ numChecked }}
                                lineage{{ numChecked > 1 ?
                                    's' : '' }}<span class="clear-button" @click="resetFilters()">&#x2715;</span></span>{{
                                    hasChecked && hasSearch ? 'AND' : '' }}
                            <span v-if="hasSearch" class="filter-type"> Cell Line Model name includes
                                <strong>"{{ filter.searchText }}""</strong>
                                <span class="clear-button" @click="updateSearchFilters('')">&#x2715;</span></span>
                        </p>
                    </div>
                    <div class="viz-row" v-if="showViz">
                        <div :class="hasGrouping ? 'has-grouping' : ''" class="sunburst-container">
                            <h4 class="viz-title">Lineage Hierarchy</h4>

                            <Sunburst :key="vizKey.sunburst" :data="treeData" :selections="filter.selections"
                                :no-results="noResults" :style="`padding-top: ${hasGrouping ? '15px' : '0'}`" />
                        </div>

                        <div v-if="hasGrouping" class="barplot-container">
                            <h4 class="viz-title">{{ groupingCategory }} Distribution</h4>
                            <BarChart v-if="hasColorBy" :key="vizKey.bar" :data="colorBy" :num-lineage="numLineage" />
                            <p v-else>
                                No results
                            </p>
                        </div>
                    </div>
                </div>

                <div id="dataTable-wrapper">
                    <div class="dt-buttons">
                        <p style="font-weight: 900; margin-left: 5px;">Cell Line Model</p>

                        <div>
                            <b-dropdown text="Hide/Show Columns" menu-class="hide-show-menu" variant="light"
                                style="margin-right: 5px;" no-flip>
                                <b-form-checkbox v-for="each in columnToggleOptions" :key="each.key"
                                    class="dropdown-item custom-control custom-checkbox b-custom-control-sm"
                                    @change="onColumnToggle(each.key)" :checked="each.selected"
                                    style="margin-left: 10px;">
                                    {{ columnNameMap[each.key].name }}
                                </b-form-checkbox>
                            </b-dropdown>
                            <b-dropdown text="Download as..." variant="light">
                                <b-dropdown-item v-for="mode in btnDownloads" :key="mode"
                                    @click="downloadDataset(mode)">
                                    {{ mode }}
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>

                    <table id="tableId" class="display" style="width:100%"></table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// http://live.datatables.net/xehimatu/1/edit
// https://datatables.net/extensions/searchpanes/examples/customFiltering/customPane.html

// https://medium.com/better-programming/creating-a-multi-filter-function-to-filter-out-multiple-attributes-javascript-react-rails-5aad8e272142
// https://gist.github.com/jherax/f11d669ba286f21b7a2dcff69621eb72

import Sunburst from "../components/Sunburst.vue";
import BarChart from '../components/BarChart.vue';

import * as d3 from "d3";
import { nest, map } from 'd3-collection';

//import Multiselect from 'vue-multiselect'

let tableId = "tableId"

export default {
    //    props: {
    // template parameter
    //   },
    // components: {
    //     Multiselect
    // },
    components: {
        Sunburst,
        BarChart,
    },
    data() {
        const columnNameMap = { // this is in same order as Table Columns, used to make columnToggle
            internalCCLFId: { name: "Internal CCLF ID", default: true },
            patientSampleSource: { name: "Patient sample source", default: false },
            DepmapModelId: { name: "DepMap Model ID, if applicable", default: true },
            CCLFPublicationId: { name: "CCLF Publication ID, if applicable", default: false },
            organSystem: { name: "Organ System", default: false },
            lineage: { name: "Lineage", default: true },
            diagnosis: { name: "Diagnosis", default: true },
            diagnosisSubtype: { name: "Diagnosis Subtype", default: true },
            rareOrCommon: { name: "Rare or Common Cancer Model?", default: true },
            pediatric: { name: 'Pediatric?', default: true },
            verificationMethod: { name: "Verification method", default: false },
            termCulture: { name: "Short term or Long term culture?", default: true },
            cultureSystem: { name: "Culture system", default: true },
            flaskConditions: { name: "Flask Conditions", default: false },
            cultureMedia: { name: "Culture media", default: false },
            passExpansion: { name: "Pass expansion?", default: false },
            statusInModelDerivationPipeline: { name: "Status in model derivation pipeline", default: false },
            patientOrPDXTumorTissue: { name: "Patient tumor tissue or PDX tumor tissue?", default: false },
            HCMIModel: { name: "HCMI model?", default: false },
            depositATCC: { name: "Deposited at ATCC?", default: false },
            accesibility: { name: "Accessibility", default: false },
            patientTumorType: { name: "Patient tumor type", default: true },
            sampleCollectionSite: { name: "Sample Collection Site", default: true },
            gender: { name: "Gender", default: true },
            ageAtCollection: { name: "Age at Collection", default: true },
            raceOrEthnicity: { name: "Race or Ethnicity", default: true },
            treatmentHistory: { name: "Treatment History", default: false },
            WESdata: { name: "WGS/WES data available at Broad?", default: true },
            RNAseqData: { name: "RNAseq data available at Broad?", default: true },
            statusInDepMap: { name: "Handed off to DepMap?", default: false },
            quarterModelAdded: { name: "Quarter model added to this list", default: false },
        }

        return {
            data: [],
            columnNameMap,
            columnToggleOptions: Object.keys(columnNameMap).map(function (d) {
                return {
                    key: d,
                    label: columnNameMap[d].name,
                    selected: columnNameMap[d].default,
                }
            }),
            /**
             * options is original options
             * selections is what's selected by checkboxes
             * selected is the options currently selected
             * columns are the columns to display for options
             */
            filter: {
                options: [],
                checked: {},
                selections: {},
                searchText: '',
                columns: ["lineage", "diagnosis", "diagnosisSubtype"]
            },
            currLayout: 'viz-table',
            layoutOptions: [
                {
                    key: 'viz-table',
                    desc: 'View sunburst and table'
                },
                {
                    key: 'table',
                    desc: 'View data table only'
                },
            ],
            vizKey: {
                sunburst: 0,
                bar: 2
            },
            tableHeight: '23vh',
            selectedSegment: null,
            selectedSegmentCategory: "",
            segmentOptions: [
                {
                    value: null,
                    text: 'None'
                },
                {
                    value: 'pediatric',
                    text: 'Pediatric'
                },
                {
                    value: 'raceOrEthnicity',
                    text: 'Race or Ethnicity'
                },
                {
                    value: 'rareOrCommon',
                    text: 'Rare or Common'
                },
                {
                    value: 'gender',
                    text: 'Gender'
                },
            ],
            colorBy: {},
            treeData: {},
            btnDownloads: [
                'CSV',
                'Excel'
            ],
            selectedFilters: [],
            pageLength: 10,
            noResults: false,
            showViz: true,
            dateUpdated: ''
        };
    },
    computed: {
        // Gets all the filters checked
        filtersChecked() {
            return Object.values(this.filter.checked).flat();
        },
        // Gets the number of filters checked
        numChecked() {
            return this.filtersChecked.length;
        },
        // Checks if there's any filters checked
        hasChecked() {
            return this.numChecked > 0;
        },
        // Checks if the user inputted into the search box
        hasSearch() {
            return this.filter.searchText.length > 0;
        },
        hasGrouping() {
            return this.selectedSegment !== null;
        },
        hasFilters() {
            return this.hasChecked || this.hasSearch;
        },
        numLineage() {
            return this.filter.checked['lineage'].length;
        },
        groupingCategory() {
            return this.segmentOptions.find(o => o.value === this.selectedSegment).text;
        },
        // Checks if there's any bar chart grouping
        hasColorBy() {
            return Object.entries(this.colorBy).length > 0;
        }
    },
    watch: {
        hasFilters(value) {
            if (this.currLayout === 'table') {
                if (value) {
                    this.tableHeight = "55.5vh"
                } else {
                    this.tableHeight = "60vh"
                }
            } else {
                this.tableHeight = '23vh';
            }

            const scrollBody = document.getElementsByClassName('dt-scroll-body')[0];
            scrollBody.style.height = this.tableHeight;
        },
        colorBy() {
            this.vizKey.sunburst += 1;
            this.vizKey.bar += 1;
        },
        vizKey(value) {
            if (value.bar === value.sunburst) {
                this.vizKey.sunburst += 1;
            }
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        /**
         * Initializes DataTable
         */
        init() {
            // path needs to be based from index.html, and file goes in public folder
            let verifiedModelsPath, mediaRecipesPath;
            d3.json('data/cclf_file_paths.json')
                .then((fileData) => {
                    return fileData;
                }).then((fd) => {
                    verifiedModelsPath = fd.find(item => item.fileName === 'cclf_verified_models').filePath;
                    mediaRecipesPath = fd.find(item => item.fileName === 'cclf_media_recipes').filePath;
                    this.dateUpdated = fd.find(item => item.fileName === 'cclf_verified_models').dateUpdated
                    Promise.all([
                        d3.tsv(verifiedModelsPath, function (d) {
                            return {
                                internalCCLFId: d["Internal CCLF ID"],
                                patientSampleSource: d["Patient sample source"],
                                DepmapModelId: d["DepMap Model ID, if applicable"],
                                CCLFPublicationId: d["CCLF Publication ID, if applicable"],
                                organSystem: d["Organ System"],
                                lineage: d["Lineage"],
                                diagnosis: d["Diagnosis"],
                                diagnosisSubtype: d["Diagnosis Subtype"],
                                rareOrCommon: d["Rare or Common Cancer Model?"],
                                pediatric: d["Pediatric?"],
                                verificationMethod: d["Verification method"],
                                termCulture: d["Short term or Long term culture?"],
                                cultureSystem: d["Culture system"],
                                cultureMedia: d["Culture media"].split(", "),
                                passExpansion: d["Pass Expansion?"],
                                statusInModelDerivationPipeline: d["Status in model derivation pipeline"],
                                flaskConditions: d["Flask Conditions"],
                                patientOrPDXTumorTissue: d["Patient tumor tissue or PDX tumor tissue?"],
                                HCMIModel: d["HCMI model?"],
                                depositATCC: d["Deposited at ATCC?"],
                                accesibility: d["Accessibility"],
                                patientTumorType: d["Patient tumor type"],
                                sampleCollectionSite: d["Sample Collection Site"],
                                gender: d["Gender"],
                                ageAtCollection: d["Age at Collection"],
                                raceOrEthnicity: d["Race or Ethnicity"],
                                treatmentHistory: d["Treatment History"],
                                WESdata: d["WGS/WES data available at Broad?"],
                                RNAseqData: d["RNAseq data available at Broad?"],
                                statusInDepMap: d["Handed off to DepMap?"],
                                quarterModelAdded: d["Quarter model added to this list"]
                            }
                        }),
                        d3.csv(mediaRecipesPath, function (d) {
                            return {
                                SOPNumber: d["SOP Number"],
                                SOPName: d["SOP Name"],
                                date: d["date"],
                                version: d["version"],
                                fileName: d["PDF"]
                            }
                        }),

                    ]).then((data) => {

                        let sopNameToFile = map();
                        data[1].forEach(function (d) { sopNameToFile.set(d.SOPName, d.fileName) })
                        this.verifiedModels = data[0]
                        this.filter.options = this.createFilterOptions(data[0], this.filter.columns); // rename createFilterOptions
                        this.filter.checked = this.createFilterSelections(data[0], this.filter.columns);
                        this.filter.selections = this.createFilterSelections(data[0], this.filter.columns);
                        let columnNameMap = this.columnNameMap;
                        const downloadModes = this.btnDownloads.map(mode => mode.toLowerCase());
                        // let columns = Object.keys(this.columnNameMap);
                        // this.columns = columns.map(function(d){
                        //     return {
                        //         key: d,
                        //         label: columnNameMap[d].name
                        //     }
                        // })
                        $(`#${tableId}`).DataTable({
                            destroy: true,
                            paging: true,
                            // fixedHeader: true,
                            scrollX: true,
                            scrollY: this.tableHeight,
                            // scroller: true,
                            scrollResize: true,
                            //  dom: '<"top"i>t<"bottom"lp><"clear">',
                            dom: 'iBtip',
                            order: [[0, 'asc']], // sorting by the input order (the first column specified)
                            data: data[0],
                            fixedColumns: true,
                            autoWidth: false,
                            buttons: downloadModes,
                            columnDefs: [
                                { width: "190px", targets: "_all" }, // Sets all columns to 100px width
                            ],
                            drawCallback: () => {
                                this.boldEntries();
                            },
                            columns: [
                                {
                                    title: `${columnNameMap["internalCCLFId"].name}`,
                                    data: 'internalCCLFId',
                                    name: 'internalCCLFId'
                                },
                                {
                                    title: `${columnNameMap["patientSampleSource"].name}`,
                                    data: 'patientSampleSource',
                                    name: 'patientSampleSource'
                                },
                                {
                                    title: `${columnNameMap["DepmapModelId"].name} <span class="info-circle" data-toggle="tooltip" title="The ID registered with the Cancer Dependency Map">&#9432;</span>`,
                                    data: 'DepmapModelId',
                                    name: 'DepmapModelId'
                                },
                                {
                                    title: `${columnNameMap["CCLFPublicationId"].name}`,
                                    data: 'CCLFPublicationId',
                                    name: 'CCLFPublicationId'
                                },
                                {
                                    title: `${columnNameMap["organSystem"].name}`,
                                    data: 'organSystem',
                                    name: 'organSystem'
                                },

                                {
                                    title: `${columnNameMap["lineage"].name}`,
                                    data: 'lineage',
                                    name: 'lineage'
                                },

                                { title: `${columnNameMap["diagnosis"].name}`, data: 'diagnosis', name: 'diagnosis' },

                                { title: `${columnNameMap["diagnosisSubtype"].name}`, data: 'diagnosisSubtype', name: 'diagnosisSubtype' },

                                { title: `${columnNameMap["rareOrCommon"].name}`, data: 'rareOrCommon', name: 'rareOrCommon' },
                                { title: `${columnNameMap["pediatric"].name}`, data: 'pediatric', name: 'pediatric' },
                                {
                                    title: `${columnNameMap["verificationMethod"].name} <span class="info-circle" data-toggle="tooltip" title="The method used to confirm a cell line as tumor">&#9432;</span>`,
                                    data: 'verificationMethod',
                                    name: 'verificationMethod'
                                },

                                {
                                    title: `${columnNameMap["termCulture"].name} <span class="info-circle" data-toggle="tooltip" title="Short term passage = ~p3-p5; Long term passage = beyond passage 5 and higher, through the end of Expansion">&#9432;</span>`,
                                    data: 'termCulture',
                                    name: 'termCulture'
                                },

                                {
                                    title: `${columnNameMap["cultureSystem"].name} <span class="info-circle" data-toggle="tooltip" title="2D adherent; 2D suspension; 2D mix (mix of adherent and suspension cells); 3D organoid cultured in matrigel">&#9432;</span>`,
                                    data: 'cultureSystem',
                                    name: 'cultureSystem'
                                },
                                {
                                    title: `${columnNameMap["flaskConditions"].name} <span class="info-circle" data-toggle="tooltip" title="What were the flask conditions?">&#9432;</span>`,
                                    data: 'flaskConditions',
                                    name: 'flaskConditions'
                                },
                                {
                                    title: `${columnNameMap["cultureMedia"].name}`,
                                    data: 'cultureMedia',
                                    name: 'cultureMedia',
                                    render: function (d) {
                                        let returned = []
                                        for (var i = 0; i < d.length; i++) {
                                            if (sopNameToFile.get(d[i]) == undefined || sopNameToFile.get(d[i]) == "") {
                                                returned.push(`<span >${d[i]}</span>`)
                                            } else {
                                                returned.push(`<a href="#" onclick="window.open('sop_pdfs/${sopNameToFile.get(d[i])}.pdf', '_blank', 'fullscreen=yes'); return false;"> ${d[i]}</a>`)
                                            }
                                        }
                                        return `<span> ${returned} </span>`

                                    }
                                },
                                {
                                    title: `${columnNameMap["passExpansion"].name} <span class="info-circle" data-toggle="tooltip" title="After the cell line was verifed as tumor, did it successfully reach the end of Expansion? (Yes; No; In Progress; No attempt yet; On Hold)">&#9432;</span>`,
                                    data: 'passExpansion',
                                    name: 'passExpansion'
                                },

                                {
                                    title: `${columnNameMap["statusInModelDerivationPipeline"].name} <span class="info-circle" data-toggle="tooltip" title="Stages in the CCLF pipeline: Nursery, Nursery Complete, Expansion, Expansion Complete, On Hold, Terminated">&#9432;</span>`,
                                    data: 'statusInModelDerivationPipeline',
                                    name: 'statusInModelDerivationPipeline'
                                },

                                {
                                    title: `${columnNameMap["patientOrPDXTumorTissue"].name}`,
                                    data: 'patientOrPDXTumorTissue',
                                    name: 'patientOrPDXTumorTissue'
                                },

                                {
                                    title: `${columnNameMap["HCMIModel"].name} <span class="info-circle" data-toggle="tooltip" title="Is this cancer model a part of the Human Cancer Models Initiative? (Yes; No; TBD = model derivation still in progress; pending = awaiting documentation; N/A = model failed expansion)">&#9432;</span>`,
                                    data: 'HCMIModel',
                                    name: 'HCMIModel'
                                },

                                {
                                    title: `${columnNameMap["depositATCC"].name} <span class="info-circle" data-toggle="tooltip" title="Has this cancer model been submitted to ATCC? (Yes; To be shipped; Pending, N/A)">&#9432;</span>`,
                                    data: 'depositATCC',
                                    name: 'depositATCC'
                                },

                                {
                                    title: `${columnNameMap["accesibility"].name} <span class="info-circle" data-toggle="tooltip" title="Where this cell line is currently accessible: (1) Available at ATCC; (2) Deposited at ATCC; not yet available online; (3) Currently at CCLF">&#9432;</span>`,
                                    data: 'accesibility',
                                    name: 'accesibility',
                                    render: function (d) {
                                        if (d == "Available at ATCC") {
                                            return `<a href="https://www.atcc.org/search#q=brod&sort=relevancy" target="_blank">${d}</a>`
                                        } else {
                                            return d
                                        }
                                    }
                                },
                                {
                                    title: `${columnNameMap["patientTumorType"].name} <span class="info-circle" data-toggle="tooltip" title="Primary; Secondary; Metastatic; Recurrent; Unknown">&#9432;</span>`,
                                    data: 'patientTumorType',
                                    name: 'patientTumorType'
                                },
                                {
                                    title: `${columnNameMap["sampleCollectionSite"].name}`,
                                    data: 'sampleCollectionSite',
                                    name: 'sampleCollectionSite',
                                },

                                {
                                    title: `${columnNameMap["gender"].name}`,
                                    data: 'gender',
                                    name: 'gender'
                                },

                                {
                                    title: `${columnNameMap["ageAtCollection"].name}`,
                                    data: 'ageAtCollection',
                                    name: 'ageAtCollection'
                                },

                                {
                                    title: `${columnNameMap["raceOrEthnicity"].name}`,
                                    data: 'raceOrEthnicity',
                                    name: 'raceOrEthnicity'
                                },

                                {
                                    title: `${columnNameMap["treatmentHistory"].name} <span class="info-circle" data-toggle="tooltip" title="Patient treatment history at the time of sample collection">&#9432;</span>`,
                                    data: 'treatmentHistory',
                                    name: 'treatmentHistory'
                                },

                                {
                                    title: `${columnNameMap["WESdata"].name}`,
                                    data: 'WESdata',
                                    name: 'WESdata'
                                },
                                {
                                    title: `${columnNameMap["RNAseqData"].name}`,
                                    data: 'RNAseqData',
                                    name: 'RNAseqData'
                                },

                                {
                                    title: `${columnNameMap["statusInDepMap"].name}`,
                                    data: 'statusInDepMap',
                                    name: 'statusInDepMap'
                                },
                                {
                                    title: `${columnNameMap["quarterModelAdded"].name} <span class="info-circle" data-toggle="tooltip" title="The quarter this cancer model was confirmed to be "verified tumor"">&#9432;</span>`,
                                    data: 'quarterModelAdded',
                                    name: 'quarterModelAdded'
                                }
                            ],
                            initComplete() {
                                var table = $(`#${tableId}`).DataTable();
                                $('[data-toggle="tooltip"]').tooltip();
                                table.columns.adjust();
                                const scrollBody = document.getElementsByClassName('dt-scroll-body')[0];
                                scrollBody.style.maxHeight = '100%';
                                table.page.len(scrollBody.clientHeight / 30).draw();
                            }
                        })

                        this.data = this.verifiedModels;
                        this.tableAdjustments();
                        this.treeData = this.structureTree();

                        const sunburstTooltip = document.createElement('div');
                        sunburstTooltip.setAttribute('id', 'sunburst-tooltip');
                        sunburstTooltip.appendChild(document.createElement('p'))
                        document.body.appendChild(sunburstTooltip)

                        this.vizKey.sunburst += 1;
                        this.vizKey.bar += 1;
                    }); // end of promises

                })
        },
        /**
         * Toggles column appearance within DataTable
         * @param name 
         */
        onColumnToggle(key) {
            var table = $(`#${tableId}`).DataTable();
            var column = table.column(`${key}:name`);
            column.visible(!column.visible());
        },
        /**
         * Converts a string into kebab case
         * @param str 
         */
        toKebab(str) {
            return str.toLowerCase().trim().replace(/\s+/g, '-');
        },
        /**
         * Changes the dropdown symbol based expanded state
         * @param selection 
         */
        toggleCarrot(selection) {
            let selectionDom = document.getElementById(`${selection}`)
            if (selectionDom.classList.contains("show")) {
                // d3.select(`#${selection}-carrot`).html("&#9650;")
                d3.select(`#${selection}-carrot`).html("+")
            } else {
                // d3.select(`#${selection}-carrot`).html("&#9660;")
                d3.select(`#${selection}-carrot`).html("-")
            }
        },
        /**
         * Populated this.filter.options with selectable options
         * @param data 
         * @param properties 
         */
        createFilterOptions(data, properties) {

            if (properties == false) {
                properties = Object.keys(data[0]);
            }
            let filterData = [];
            for (var prop = 0; prop < properties.length; prop++) { // for each property, make a nest of its values. push these nests into parent array 'nestedProperties'

                let nestedProp = nest()
                    .key(function (d) { return d[properties[prop]] })
                    .entries(data.filter(function (d) { return d[properties[prop]] != "" }))

                nestedProp.forEach(function (d) { // each checkbox option nest
                    d.property = properties[prop];
                })
                // categories
                filterData.push({
                    key: properties[prop],
                    values: nestedProp,
                    total: nestedProp.length
                })
            }

            /**
             * Transforms the names of labels into acronyms
             * (removes any lowercase letters and numbers)
             * @param str The original label for wedge
             */
            const shortenLabel = (str) => {
                let result;
                if (str.split(' ').length < 2) {
                    result = str.slice(0, 4);
                } else {
                    result = str.split(' ').map(word => word.charAt(0)).filter(char => char === char.toUpperCase() && isNaN(char)).join('');
                }
                return result.toUpperCase();
            }

            filterData.forEach(function (d) {
                d.values = d.values.sort(function (a, b) {
                    return d3.descending(a.values.length, b.values.length)
                })
                d.values.forEach(function (each) {
                    each.shorten = shortenLabel(each.key);
                    each.total = each.values.length;
                })
            })

            return filterData;
        },

        createFilterSelections(data, properties) {
            if (properties == false) {
                properties = Object.keys(data[0]);
            }
            let obj = {}
            for (var prop = 0; prop < properties.length; prop++) {
                obj[properties[prop]] = []
            }
            return obj
        },
        constructFilterString(data) {
            return data.map(function (d) {
                return `(?=.*${d.internalCCLFId})`
            })
        },
        removeItem(array, item) {
            for (var i = 0; i < array.length; i++) {
                const isNa = array[i].name && array[i].name === item.name && array[i].parent && array[i].parent.name === item.parent.name;
                if (array[i] === item || isNa) {
                    array.splice(i, 1);
                }
            }
            return array;
        },
        /**
         * 
         * @param {*} obj is selections. 
         * the function returns just the attributes that arent empty 
         * but this isn't necessary. filter function should just account for empty arrays
         */
        checkProperties(obj) {
            var returned = {};
            for (var key in obj) {
                if (obj[key].length !== 0) {
                    returned[key] = obj[key]
                }
            }
            return returned;
        },
        filterCategorySelection(data, category, select) {
            let result = [];
            // iterate through all the given data entries
            for (let index = 0; index < data.length; index++) {
                // data value within category
                let dataValue = data[index];
                // if the data element [category key] is found within every category and element index
                if (dataValue[category] === select) {
                    // add the element to the filtered array
                    result.push(dataValue);
                }
            }

            return result;
        },
        /**
         * Iterates through all options within a category
         * @param data 
         * @param category 
         * @param selections 
         */
        filterCategory(data, category, selections) {
            let index = 0;
            let result = this.filterCategorySelection(data, category, selections[index]);
            while (selections.length > 1 && index < selections.length) {
                result = result.concat(this.filterCategorySelection(data, category, selections[index]));
                index += 1;
            }
            return result;
        },
        /**
         * Manipulates the sizes and styles of containers when a button 
         * within the layout row is selected
         * @param layout 
         */
        changeLayout(layout) {
            // set current layout for button selector
            this.currLayout = layout;
            var table = $(`#${tableId}`).DataTable();
            switch (layout) {
                case 'table':
                    this.showViz = false;
                    this.tableHeight = this.hasFilters ? '55.5vh' : '60vh';
                    break;
                default:
                    this.showViz = true;
                    this.tableHeight = '23vh';
                    break;
            }
            const scrollBody = document.getElementsByClassName('dt-scroll-body')[0];
            scrollBody.style.height = this.tableHeight;
            table.page.len(scrollBody.clientHeight / 30).draw();
        },

        getColumnCounts(column, data) {
            let barData = {};
            for (let i = 0; i < data.length; i++) {
                const entry = data[i];

                if (barData[entry[column]]) {
                    barData[entry[column]] += 1;
                } else {
                    barData[entry[column]] = 1;
                }
            }
            if (barData['-']) delete barData['-'];
            if (barData['undefined']) delete barData['undefined'];

            const barEntries = Object.entries(barData);
            barData = Object.fromEntries(barEntries.sort((a, b) => b[1] - a[1]))
            return barData;
        },

        /**
         * Finds the number of N/A values within the parent entries
         * @param name 'N/A'
         * @param prop 'diagnosisSubtype'
         * @param parent 'Lung', 'Bone', etc.
         * @param values 'diagnosis'
         */
        findNaValue(name, prop, parent, values) {
            let total = 0;
            for (let index = 0; index < values.length; index++) {
                const p = values[index];
                if (parent === p.key) {
                    for (let j = 0; j < p.values.length; j++) {
                        const entry = p.values[j];
                        if (entry[prop] === name) total += 1;
                    }
                }
            }
            return total;
        },

        /**
         * 
         * @param values All values within category
         * @param name name of the value looking for
         */
        getShortName(values, name) {
            return values.find(v => v.key === name).shorten;
        },

        /**
         * Changes the filtering options into tree data.
         */
        structureTree() {
            let dataCopy = [...this.filter.options];
            let result = [];

            if (dataCopy[0]) {
                // Add outerpost layer for lineage
                for (let i = 0; i < dataCopy[0].values.length; i++) {
                    const option = dataCopy[0].values[i];
                    const lentry = {
                        name: option.key,
                        property: option.property,
                        short: option.shorten,
                        total: option.total,
                        children: []
                    };
                    result.push(lentry);
                }

                for (let j = 0; j < result.length; j++) {
                    const parent = result[j];
                    // sorts N/A values at the end so the filter option is added
                    dataCopy[0].values[j].values = dataCopy[0].values[j].values.sort((e) => e.diagnosisSubtype === "N/A" ? 1 : -1);

                    // iterate through all the data entries under filter
                    for (let k = 0; k < dataCopy[0].values[j].values.length; k++) {
                        const entry = dataCopy[0].values[j].values[k];
                        const property = dataCopy[1].key;
                        const name = entry[property];
                        const child = {
                            name,
                            property,
                            short: this.getShortName(dataCopy[1].values, name),
                            value: dataCopy[1].values.find(d => d.key === name).total,
                            children: []
                        };

                        // if there is a diagnosis and the diagnosis isn't already in the array
                        if (name && !parent.children.some((d) => { return d["name"] === name; })) {
                            // if there is a diagnosis subtype
                            parent.children.push(child);
                            // delete parent.value
                        }

                        if (parent.children.length > 0) {
                            // for each diagnosis
                            parent.children.forEach((diagnosis) => {
                                const subProperty = dataCopy[2].key;
                                const subName = entry[subProperty];
                                const doesntInclude = !diagnosis.children.some((d) => { return d["name"] === subName; });

                                // check if the diagnosis is the current row, then check if there is a subtype
                                if (diagnosis.name === name && doesntInclude && subName !== 'N/A') {
                                    diagnosis.children.push({ name: subName, property: subProperty, short: this.getShortName(dataCopy[2].values, subName), value: dataCopy[2].values.find((ds) => ds.key === subName).total });
                                } else if (diagnosis.name === name && subName === 'N/A' && doesntInclude && diagnosis.children.length > 0) {
                                    diagnosis.children.push({ name: subName, property: subProperty, value: this.findNaValue(subName, subProperty, name, dataCopy[1].values), parent: { name, property } })
                                }
                                // puts N/A at the end of the list
                                diagnosis.children.sort((a) => a.name === "N/A" ? 1 : -1);
                            })
                        } else {
                            delete parent[children];
                        }
                    }
                }
            }

            return { name: 'Patient Derived Models', children: result };
        },

        /**
         * Checks if the current option is within selections object
         * @param category The category that's being rendered
         */
        isSelected(category) {
            const property = category.property;
            const selection = category.name ? category.name : category.key;
            return this.filter.checked[property].includes(selection);
        },
        /**
         * Checks if a diagnosis subtype is selected
         * @param subtype 
         * @param diagnosis 
         */
        checkDiagnosisSubtype(subtype, diagnosis) {
            const property = subtype.property;
            const selection = subtype.name;
            // if NA then check if parent applies as well
            const nAIndex = this.filter.checked[property].findIndex(c => c.name === selection && c.parent.name === diagnosis.name);
            if (selection === 'N/A')
                return nAIndex > -1
            else
                return this.filter.checked[property].includes(selection);
        },
        /**
         * Manipulate elements rendered by DataTables
         */
        tableAdjustments() {
            var table = $(`#${tableId}`).DataTable();

            // adds hover tooltips to values
            const columns = document.getElementsByTagName('td');
            for (let cell of columns) {
                cell.setAttribute('title', cell.innerHTML);
            }

            // Remove the top label text for table
            const tableInfo = document.getElementsByClassName('dt-info');
            if (tableInfo.length > 1) tableInfo[0].remove();

            const parentDiv = document.getElementById("tableId_wrapper");

            const dtInfoDiv = document.getElementsByClassName("dt-info")[0];
            const dtPagingDiv = document.getElementsByClassName("dt-paging")[0];

            const newDiv = document.createElement("div");
            newDiv.setAttribute('class', 'spaced-elements');

            newDiv.appendChild(dtInfoDiv);
            newDiv.appendChild(dtPagingDiv);

            if (dtInfoDiv && dtPagingDiv) parentDiv.appendChild(newDiv);

            // Removes actual download buttons
            const dtButtons = document.getElementsByClassName('dt-buttons');
            if (dtButtons.length > 1) dtButtons[1].remove();

            for (let i = 0; i < this.columnToggleOptions.length; i++) {
                const column = this.columnToggleOptions[i];
                if (!column.selected) {
                    var col = table.column(`${column.key}:name`);
                    col.visible(!col.visible());
                }
            }

            this.boldEntries();

        },
        boldEntries() {
            const dtInfo = document.getElementsByClassName('dt-info')[0];
            const idxOfEntries = dtInfo.innerHTML.indexOf('entries') - 1;
            const idxOf = dtInfo.innerHTML.indexOf('of') + 3;
            const numEntries = `<strong>${dtInfo.innerHTML.substring(idxOf, idxOfEntries)}</strong>`;
            dtInfo.innerHTML = dtInfo.innerHTML.slice(0, idxOf) + numEntries + dtInfo.innerHTML.slice(idxOfEntries);
        },
        /**
         * Downloads the dataset based on the given file type
         * @param mode 
         */
        downloadDataset(mode) {
            var table = $(`#${tableId}`).DataTable();
            table.button(`.buttons-${mode.toLowerCase()}`).trigger();
        },
        /**
         * Determines whether to select or deselect all child elements
         * @param property category (lineage, diagnosis, etc.)
         * @param selection option
         * @param action select or deseelct
         */
        applyToChildren(property, selection, action) {
            const subtypeAction = (subtype) => {
                switch (action) {
                    case 'select':
                        if (subtype.name === 'N/A') this.filter.checked[subtype.property].push(subtype);
                        else this.filter.checked[subtype.property].push(subtype.name);
                        break;
                    case 'deselect':
                        if (subtype.name === 'N/A') this.removeItem(this.filter.checked[subtype.property], subtype);
                        else this.removeItem(this.filter.checked[subtype.property], subtype.name);
                        break;
                }
            }
            if (property === 'lineage') {
                // finds diagnosis applied to current lineage
                const children = this.treeData.children.find(l => l.name === selection).children;
                for (let i = 0; i < children.length; i++) {
                    const child = children[i];
                    switch (action) {
                        case 'select':
                            this.filter.checked[child.property].push(child.name);
                            break;
                        case 'deselect':
                            this.removeItem(this.filter.checked[child.property], child.name);
                            break;
                    }
                    // finds all diagnosis subtypes
                    for (let j = 0; j < child.children.length; j++) {
                        const subtype = child.children[j];
                        subtypeAction(subtype);
                    }
                }
            } else if (property === 'diagnosis') {
                let diagnosis;
                for (let i = 0; i < this.treeData.children.length; i++) {
                    const lineage = this.treeData.children[i];
                    // if the diagnosis is under the lineage, then assign the diagnosis
                    if (lineage.children.findIndex(d => d.name === selection) > -1) {
                        diagnosis = lineage.children.find(d => d.name === selection);
                    }
                }
                // if there's a diagnosis found
                if (diagnosis && diagnosis.children) {
                    for (let i = 0; i < diagnosis.children.length; i++) {
                        const subtype = diagnosis.children[i];
                        subtypeAction(subtype);
                    }
                }
            }
        },
        /**
         * Resets all checked filters
         */
        resetFilters() {
            this.data = this.verifiedModels;
            this.filter.options = this.createFilterOptions(this.verifiedModels, this.filter.columns);
            this.filter.checked = this.createFilterSelections(this.verifiedModels, this.filter.columns);
            this.filter.selections = this.createFilterSelections(this.verifiedModels, this.filter.columns);
            this.applyFilters();
        },
        /**
         * Finds the most distinguishing selection from all the entries within the dataset
         * for example, if an entry has a diagnosis subtype, then that will
         * be the entry's most distinguishing selection
         * @param data 
         * @returns
         */
        findSelection(data) {
            let result = {};
            let allEntries = [...data]
            // diagnosis subtype -> lineage
            for (let i = this.filter.columns.length - 1; i >= 0; i--) {
                const col = this.filter.columns[i];
                result[col] = [];
                // goes from end of dataset to beginning
                if (this.hasSearch || this.hasChecked) {
                    for (let j = allEntries.length - 1; j >= 0; j--) {
                        const entry = allEntries[j];
                        const nonEmpty = entry[col] !== 'N/A';
                        if (!result[col].includes(entry[col]) && nonEmpty) {
                            result[col].push(entry[col]);
                            allEntries.splice(j, 1);
                        } else if (result[col].includes(entry[col])) {
                            allEntries.splice(j, 1);
                        }
                    }
                }
            }
            return result;
        },
        /**
         * Actions for when a filter option is selected
         * @param selection 
         * @param property 
         */
        onCheckFilter(value) {
            let selection = value.name ? value.name : value.key;
            let property = value.property;
            // if the value is a N/A value
            if (selection === 'N/A') {
                if (this.checkDiagnosisSubtype(value, value.parent)) {
                    this.removeItem(this.filter.checked[property], value);
                } else {
                    this.filter.checked[property].push(value);
                }
            } else {
                if (this.isSelected(value)) {
                    this.removeItem(this.filter.checked[property], selection);
                    this.applyToChildren(property, selection, 'deselect');
                } else {
                    this.filter.checked[property].push(selection);
                    this.applyToChildren(property, selection, 'select');
                }

            }

            this.applyFilters();
        },
        updateSearchFilters(value) {
            this.filter.searchText = value;
            this.applyFilters();
        },
        applyFilters() {
            // This is the core filtering logic.  This works by first filtering
            // the data by the tree filter selection, then applying the string search.
            // Logically, this works, but the sunburst only gets the first set of filters.
            // Ideally, if you do a string search alone, the sunburst should show the 
            // categories that include cell lines satisfying the string search.

            // data.filter(d => {
            //     if: 
            //     d passes all filters in propertyValueFilters (which are ORed together)
            //     AND
            //     d passes all filters in stringSearchFilter (there will only ever be one filter in here, the current search string)
            //     then:
            //     return d
            //     })// removes elements with the same cellModelId value
            // removes elements with the same cellModelId value

            const checkedFilters = (entry, category) => {
                const notAppIndex = this.filter.checked[category].findIndex(e => e.name === 'N/A');
                let naArr = this.filter.checked[category].map(d => { if (d.name === 'N/A') return d })
                naArr = naArr.filter((n) => n !== undefined);
                if (notAppIndex > -1 && category === 'diagnosisSubtype') {
                    const parent = this.filter.checked[category][notAppIndex].parent;
                    const hasNaEntry = (e, c, p) => e[p.property] === p.name && e[c] === 'N/A';
                    // if there's multiple values within diagnois
                    if (this.filter.checked[category].length > 1) {
                        // if there's multiple NA values
                        if (naArr.length > 1) {
                            return naArr.some((na) => hasNaEntry(entry, category, na.parent));
                        } else {
                            return hasNaEntry(entry, category, parent) || this.filter.checked[category].includes(entry[category]);
                        }
                    }
                    else {
                        return hasNaEntry(entry, category, parent);
                    }
                } else {
                    return this.filter.checked[category].includes(entry[category]);
                }
            }

            const result = this.verifiedModels.filter(entry => {
                let matchesChecked = Object.keys(this.filter.checked).some((c) => checkedFilters(entry, c));
                let matchesSearch = entry['internalCCLFId'].toLowerCase().includes(this.filter.searchText.toLowerCase());
                if (this.hasSearch && this.hasChecked) return matchesSearch && matchesChecked;
                else if (!this.hasSearch && this.hasChecked) return matchesChecked;
                else if (this.hasSearch && !this.hasChecked) return matchesSearch;
                else return true
            });

            if (result.length < 1 && !Array.isArray(result[0])) result.push([])
            this.noResults = Array.isArray(result[0])
            if (this.hasGrouping) this.colorBy = this.getColumnCounts(this.selectedSegment, result)
            this.data = result;
            this.filter.selections = this.findSelection(result);


            let unqObjs = [...new Map(result.map(o => [o["internalCCLFId"], o])).values()];

            var table = $(`#${tableId}`).DataTable();
            let stringSearch = this.constructFilterString(unqObjs);
            // IMPORTANT: this line needs to know which number column is CellModelId. Currenlty it is 29
            table.column(0).search(stringSearch.join("|"), true, false, true).draw();

            this.vizKey.sunburst += 1;
            this.vizKey.bar += 1;

            const noResultText = document.getElementById('no-result-text');
            if (this.noResults) {
                const scrollBody = document.getElementsByClassName('dt-scroll-body')[0];
                const noResults = document.createElement('p');
                noResults.setAttribute('id', 'no-result-text')
                noResults.style.textAlign = 'center';
                noResults.style.marginTop = '4%'
                noResults.innerHTML = 'No results';
                scrollBody.appendChild(noResults)
            } if (noResultText) {
                noResultText.remove();
            }

            this.boldEntries();
        },
        cutText(text) {
            const limit = 16;
            return text.length >= limit ? text.slice(0, limit) + '...' : text;
        },
    },
}
</script>

<style>
table.dataTable tbody td {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dt-info {
    width: fit-content;
}

.dt-paging {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.dt-paging>nav {
    width: fit-content;
}

div.dt-container .dt-paging .dt-paging-button {
    padding: 0;
}

.dt-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dt-button-split>.dt-button {
    padding: 0 10px !important;
    margin-right: 0 !important;
    font-size: 18px !important;
    background: none !important;
    border: 1px solid lightgray !important;
}

.dt-button:hover {
    background-color: lightgray !important;
}

.dt-column-title {
    font-size: 16px;
}

.hide-show-menu {
    width: fit-content;
    height: 300px;
    overflow-y: scroll;
    overflow-x: clip;
}

.b-dropdown button {
    border: 1px solid lightgray;
}

/* .dt-scroll:after {
    height: 85%;
    width: 6em;
    position: absolute;
    content: "";
    z-index: 1;
    top: 0;
    right: 0;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
} */

.spaced-elements {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}
</style>


<style scoped>
.viz-title {
    position: absolute;
    width: 250px;
    height: fit-content;
    margin: 10px 10px 0;
}

.container-fluid {
    overflow-x: clip;
    padding-left: 1%;
    padding-right: 2%;
}

table.dataTable {
    table-layout: fixed;
    /* Forces table to respect set column widths */
}

.sunburst-container,
.barplot-container {
    max-width: 100%;
    width: 100%;
    min-width: 45%;
    border-radius: 10px;
    border: 1px solid lightgray;
}

.barplot-container>p {
    text-align: center;
    margin-top: 30%;
}

.filter-indicator {
    width: 100%;
    margin-bottom: 5px;
    padding: 5px 10px;
    border-radius: 10px;
    font-style: italic;
    background-color: rgba(0, 68, 128, 0.25);
}

.pills-container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.filter-pill {
    margin-right: 5px;
    margin-bottom: 5px;
    width: fit-content;
    border-radius: 15px;
    padding: 5px 10px;
    background-color: whitesmoke;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}

/* .tool-page{
    height:90vh;
} */

.container-full-width {
    width: 100vw;
    height: 100vh;
}

#filter-panel-wrapper {
    height: 65%;
    overflow-y: scroll;
}

/* 
#filter-panel-wrapper:after {
    position: absolute;
    width: 80%;
    content: "";
    z-index: 1;
    bottom: 25px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 100%);
    height: 4em;
} */

:focus {
    outline: -webkit-focus-ring-color auto 1px;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 0px;
}

a .dt-button {
    padding: 10px !important;
}

.layout-buttons {
    display: flex;
    flex-direction: row;
}

.layout-buttons>button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid lightgray;
}

.layout-buttons>button>img {
    height: 20px;
}

.layout-buttons>button:nth-child(2) {
    border-radius: 10px 0 0 10px;
}

.layout-buttons>button:last-child {
    border-radius: 0 10px 10px 0;
}

#data-panel {
    padding: 0;
}

#dataTable-wrapper {
    width: 100%;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid lightgray;
}

.filter-tools {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
}

.filter-group {
    max-height: 100%;
    overflow-y: clip;
    padding: 5%;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid lightgray;
}

.dropdown-menu-button {
    padding-left: 0;
}

.dropdown-menu-button>p {
    margin-left: auto;
    color: silver;
}

ul {
    padding-left: 0;
}

.custom-control {
    border-bottom: unset;
}

#filter-panel-wrapper ul {
    margin: 0px;
}

#filter-panel-wrapper li {
    margin: unset;
    font-size: .875rem;
    line-height: 1.5rem;
}

#filter-panel-wrapper .dropdown-menu-button {
    justify-content: unset;
    padding: unset;
}

#filter-panel-wrapper .dropdown-menu-carrot {
    margin-right: 5px;
    font-size: 14px;
    font-weight: bold;
    width: 9px;
    position: relative;
    display: inline-block;

}

.viz-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    margin-bottom: 5px;
}

.viz-row {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: stretch;
    gap: 10px;
}

.search-container {
    position: relative;
    width: 100%;
}

#cell-model-search {
    width: 100%;
    padding-right: 40px;
    padding: 10px;
}

#clear-search {
    position: absolute;
    top: 15%;
    right: 10px;
}

.clear-button {
    cursor: pointer;
    margin-left: 5px;
}

.filter-type {
    margin: 0 5px;
    color: white;
    background-color: #004480;
    border-radius: 10px;
    padding: 4px 8px;
}

.clear-checked-filters {
    margin: 10px 0;
    color: black;
    background: rgba(0, 68, 128, 0.25) !important;
    border: 1px solid darkgray
}
</style>
