<template>
    <div id="publication-page" class="container">
        <h2>Publications</h2>
        <div class="publication-wrapper">
            <ul class="no-bullet-ul lg-ul-li publication-ul">
                <li class="publication-li" v-for="publication in publications" :key="publication.id"
                    :id="publication.id">
                    <!-- <h5 class="publication-publication"><i>{{ publication.publication }}</i> {{publication.volume}} {{publication.number}} {{publication.pages}} | {{ publication.year}} </h5> -->
                    <h5 class="publication-publication"><i>{{ publication.publication }}</i> | {{ publication.date }}
                    </h5>
                    <h3 class="publication-title"><a :href=publication.url target="_blank">{{ publication.title }}</a>
                    </h3>
                    <h5 class="publication-author">{{ publication.authors }}</h5>
                    <p class="publication-abstract">{{ publication.abstract }}</p>

                    <!-- <h5 class="publication-publication">{{ publication.publisher }} {{publication.volume}} {{publication.number}} {{publication.pages}} doi: <span class="publication-doi">{{publication.doi}}</span></h5> -->


                    <!-- <h5 class="publication-publication">doi: <span class="publication-doi">{{publication.doi}}</span></h5> -->

                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import * as d3 from "d3";
export default {
    data() {
        return {
            publications: []
        };
    },
    mounted() {
        let pubPath;
        d3.json('data/cclf_file_paths.json')
        .then((fileData) => {
            pubPath = fileData.find(item => item.fileName === 'cclf_publications').filePath;
        })
        .then(() => {
            Promise.all([
                d3.tsv(pubPath, function (d, i) {
                    return {
                        authors: d["Authors"],
                        title: d["Title"],
                        publication: d["Publication"],
                        volume: d["Volume"],
                        number: d["Number"],
                        pages: d["Pages"],
                        year: d["Year"],
                        date: d["Date"],
                        publisher: d["Publisher"],
                        url: d["link"],
                        doi: d["doi"],
                        abstract: d["Abstract"],
                        id: i
                    }
                })
            ]).then((data) => {
                data[0].forEach(function (d) {
                    if (d.number) {
                        d.number = `(${d.number}):`
                    }
                    let authors = d.authors.split("; ")
                    d.authors = `${authors[0]}, et al.`;

                    d.dateFormat = new Date(d.date);
                    var formatDate = d3.timeFormat("%m.%d.%Y");
                    d.date = formatDate(d.dateFormat);
                })
                this.publications = data[0].sort(function (a, b) { return d3.descending(a.dateFormat, b.dateFormat) });
            })
        });
    }
}


</script>

<style scoped>
#publication-page>.publication-wrapper ul li {
    margin: 0rem 0rem 1.75em 0em;
    padding: 0em 0rem 1.75em 0em;
}
</style>
