<template>
<footer>
    <!-- <div id="footer-wrapper"> -->
        <div class="row-justify">
            <div class="align-middle">
                <img id="footer-logo" src="@/assets/images/broad-logo.svg" alt="broad logo">
                <ul class="no-bullet-ul">
                    <li><br>Website last updated {{ dateUpdated }}</li>
                </ul>
            </div>
            <div class="align-middle">
                <h5 class="h-caps">Connect</h5>
                <ul class="no-bullet-ul">
                    <li><a id="contact-us_toggle-btn" class="" @click="toggleModal">
                        Contact Us
                        <!-- <router-link to="/contact-us">Contact Us</router-link> -->
                    </a></li>
                    <li><a href="mailto:cellfactory@broadinstitute.org">cellfactory@broadinstitute.org</a></li>
                    <li>
                        Cancer Cell Line Factory <br/>
                        300 Binney Street, Room 5222 <br/>
                        Cambridge, MA 02142
                    </li>
                    
                </ul>
            </div>
        </div>
    <!-- </div>     -->
    <b-modal ref="contact-us_modal" hide-footer title="">
        <div class="d-block text-center">
            <iframe class="contact-us_popup_form" src="https://docs.google.com/forms/d/e/1FAIpQLSf6SWeAmjZLD7WHKt1Pm36tX2vjGqQiyXymPpP2dlComG6nKw/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
    </b-modal>
</footer>
</template>

<script>
import * as d3 from "d3";

export default {
    name: 'FooterComponent',
    data() {
        return {
            dateUpdated: ''
        }
    },
    methods: {
        toggleModal() {
            this.$refs['contact-us_modal'].toggle('#contact-us_toggle-btn')
        }
    },
    mounted() {
        d3.json('data/date_updated.json')
        .then((d) => {
            return d
        }).then((d) => {
            this.dateUpdated = d.date;
        })
    }
}
</script>

<style scoped>
footer {
    padding:1.5rem 1.5rem 1.5rem 1rem;
    background-color:#5597D1;
    background-color:#004480;
    color:white;
}
#footer-logo{
    width:200px;
    margin:0rem 1rem 3rem 0rem;
}
footer a{
    color:white;
    border-bottom:.5px solid white;
}
footer a:hover{
    opacity:0.8;
    color:white;
    border-bottom:.5px solid white;
}

.h-caps{
    color:white;
}
ul {
  display: block;
  list-style-type: none;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

@media screen and (min-width: 200px) and (max-width: 768px) {


   footer {
        padding:1.2rem 1rem 1rem 1rem;
    }
    #footer-logo{
        width:180px;
        margin:0rem 1rem 3rem 0rem;
    }

}
</style>