<template>
    <div :id="id" />
</template>

<script>
import * as LatticeLib from "lattice-viz";
import * as d3 from "d3";

export default {
    name: 'cclf-bar-chart',
    data() {
        return {
            barData: {},
            id: 'bar-plot',
            palette: ['#F57062', '#FFA675', '#F6D076', '#559CDA', '#49CA7D', '#C049CA', '#FF98BD', '#199099', '#FBD7C3'],
        }
    },
    computed: {
        totalEntries() {
            return Object.entries(this.data).length;
        },
        currPalette() {
            return this.palette.slice(0, this.totalEntries);
        },
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        numLineage: {
            type: Number,
            default: 0,
            required: false
        },
    },
    methods: {
        transformData() {
            let result = [];
            for (let i = 0; i < this.totalEntries; i++) {
                const entry = Object.entries(this.data)[i];
                const bar = {
                    x: entry[1],
                    y: entry[0],
                    c: this.palette[i]
                };
                result.push(bar);

            }
            return result;
        },

    /**
     * Formats the text within the tooltip
     * remove x: y: format
     * @param {*} text The original tooltip text
     * @returns 
     */
        changeTooltipText(index) {
            const dataEntries = Object.entries(this.data);
            return `${dataEntries[index][0]}: ${dataEntries[index][1]}`;
        },

        /**
         * Consolidates labels with an ellipsis based on a limit
         * @param text the label to be truncated
         */
        cutText(text) {
            const limit = 12;
            return text.length >= limit ? text.slice(0, limit) + '...' : text;
        },
        /**
         * Sets up plot config and executes Lattice function
         * @param width 
         */
        renderViz(width) {
            this.barData = this.transformData();
            const plotConfig = {
                width: width + width / 2,
                height: width - 15,
                padding: {
                    top: 20,
                    left: 100,
                    bottom: 50,
                    right: 50
                },
                axis: {
                    x: {
                        title: 'Number of Cell Line Models',
                        scaleType: "linear",
                        orientation: "bottom",
                        padding: 0.15,
                        textAngle: 0,
                        textAnchor: null,
                        display: true,
                        hideAxis: false,
                        hideTicks: false,
                        hideLabels: false,
                        hideTitle: false,
                        min: 0,
                    },
                    y: {
                        orientation: "left",
                        padding: 0.15,
                        textAngle: 0,
                        textAnchor: null,
                        display: true,
                        hideAxis: false,
                        hideTicks: false,
                        hideLabels: false,
                        hideTitle: true,
                    },
                },
                tooltip: {
                    enabled: true,
                    id: "bar-plot-tooltip"
                }
            };
            LatticeLib.plot(this.barData, "barplot", this.id, plotConfig);
        },
        /**
         * Changes tooltip opacity to be 0 and clears HTML
         */
        hideTooltip() {
            d3.select('#bar-plot-tooltip')
                .style('opacity', 0)
        },
        /**
         * Outlines a bar and adds text to tooltip
         * @param _ MouseEvent
         * @param n data from bar selected
         */
        barMouseover(_, n) {
            const isCurrValue = (d) => n.y === d.y;
            d3.selectAll(".ljs--bar")
                .data(this.barData)
                .join("rect")
                .attr("stroke-width", d => isCurrValue(d) ? 2 : 0)
                .attr("stroke-opacity", d => isCurrValue(d) ? 0.5 : 0)
                .attr("stroke", d => isCurrValue(d) ? 'black' : '');

            d3.select('#bar-plot-tooltip')
                .html(`${n.y}: ${n.x}`)
                .style('opacity', 1)
        },
        /**
         * Removes stroke when mouse is out of bar
         */
        barMouseout() {
            d3.selectAll(".ljs--bar")
                .data(this.barData)
                .join("rect")
                .attr("stroke-width", 0)
                .attr("stroke-opacity", 0);

            this.hideTooltip();
        },
        tickMouseEnter(e, n) {
            let [mouseX, mouseY] = d3.pointer(e, d3.select(`${this.id}`));

            d3.select('#bar-plot-tooltip')
                .style('opacity', 1)
                .style('display', 'inline')
                .style('top', `${mouseY + 5}px`)
                .style('left', `${mouseX + 5}px`)
                .html(`${n.y}: ${n.x}`)
        },
        /**
         * Resize the visualization when window size change
         */
        onWindowResize() {
            var prevHeight = barplot.clientHeight;
            let bpWidth = prevHeight;
            let prevWidth = barplot.clientWidth;

            const svg = document.getElementById(`${this.id}-svg`)
            const width = barplot.clientWidth;
            const height = barplot.clientHeight;
            // window width is increasing
            if (width > prevWidth || height > prevHeight) {
                bpWidth = barplot.clientHeight;
            } else {
                bpWidth = Math.min(barplot.clientHeight, barplot.clientWidth)
            }
            if (svg) svg.remove();
            if (this.totalEntries > 0 && bpWidth > 0) this.renderViz(bpWidth);
        },
        vizAdjustments() {
            let barplot = document.getElementsByClassName('barplot-container')[0];
            const width = barplot.clientHeight
            const yAxis = document.getElementsByClassName('ljs--y-axis')[0];
            yAxis.setAttribute('font-size', 14)
            const yTicks = yAxis.getElementsByClassName('tick');
            for (let index = 0; index < yTicks.length; index++) {
                const label = yTicks[index].getElementsByTagName('text')[0];
                label.innerHTML = this.cutText(label.innerHTML);
            }

            const barplotSvg = document.getElementById('bar-plot-svg');
            barplotSvg.setAttribute('height', width - 20);

            const xAxisText = document.getElementsByClassName('ljs--x-axis-title')[0];
            xAxisText.setAttribute('y', width - 50);

            d3.select('#bar-plot-svg')
                .on('mouseover', () => {
                    const barplotTooltip = document.getElementById('bar-plot-tooltip')
                    if (barplotTooltip.innerHTML.length < 1) barplotTooltip.style.opacity = 0;
                })
            
        }
    },

    mounted() {
        let barplot = document.getElementsByClassName('barplot-container')[0];
        let bpWidth = barplot.clientHeight;
        barplot.removeAttribute('width');
        if (this.totalEntries > 0 && bpWidth > 0) this.renderViz(bpWidth);

        d3.select('.ljs--y-axis')
            .selectAll(".tick")
            .data(this.barData)
            .join("g")
            .on("mouseenter", this.tickMouseEnter)
            .on('mouseleave', this.hideTooltip);

        d3.selectAll(".ljs--bar")
            .data(this.barData)
            .join("rect")
            .on("mouseenter", this.barMouseover)
            .on("mouseout", this.barMouseout);

        window.addEventListener('resize', this.onWindowResize);

        this.vizAdjustments();
    }
}
</script>

<style>
.ljs--x-axis-title {
    font-size: 11pt;
}

#bar-plot-tooltip {
    pointer-events: none;
    z-index: 100;
    position: absolute;
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
}

#bar-plot {
    padding-top: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

text {
    font-family: 'Lato', sans-serif;
}
</style>